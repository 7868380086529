<template>
  <BaseSection>
    <CarCatalogGrid
      v-if="data?.style === 'cards'"
      :title="data?.title"
      :cta-text="data.cta_button?.button_text"
      :cta-link="data.cta_button?.button_link"
      :car-list="(carList as CarCard[]) || []"
    />
    <CarGrid
      v-if="data.style === 'grid'"
      :title="data?.title as string"
      :cta-text="data?.cta_button?.button_text"
      :cta-link="data?.cta_button?.button_link"
      :items="(carList as CarCard[]) || []"
    />
  </BaseSection>
</template>

<script setup lang="ts">
import CarCatalogGrid from '../grids/CarCatalogGrid.vue';
import CarGrid from '../grids/CarGrid.vue';
import BaseSection from '../BaseSection.vue';
import type { CarCard } from '~/types';
import { apiVehicleToCarCardType } from '~/lib/transformers';
import { type LocaleType } from '~/composables';
import { useApi } from '~/composables/useApi';

// eslint-disable-next-line no-undef
const { $i18n } = useNuxtApp();
const { getFeaturedCars } = useApi();

interface Props {
  data?: {
    style: 'cards' | 'grid'
    title?: string
    cta_button: {
      button_text: string
      button_link: string
    }
  }
}

withDefaults(defineProps<Props>(), {
  data () {
    return {
      style: 'cards' as const,
      cta_button: {
        button_text: 'Ver todos',
        button_link: '/subscription/catalog',
      },
    };
  },
});

async function fetchCars () {
  try {
    const { data } = await getFeaturedCars();
    return apiVehicleToCarCardType(data.results, -1, $i18n.locale.value as LocaleType);
  } catch (error) {
    const err = '[BlockCarCatalog error]: Error fetching car list' + JSON.stringify(error);
    throw new Error(err);
  }
}

// eslint-disable-next-line no-undef
const { data: carList, error } = await useAsyncData('carList', () => fetchCars());

if (error.value) {
  // eslint-disable-next-line no-console
  console.log('[BlockCarCatalog error]: ', error.value);
}
</script>
