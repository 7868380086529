<template>
  <div class="car-catalog-grid">
    <BaseTitle
      v-if="!!title"
      level="h2"
      class="mb-12"
    >
      {{ title }}
    </BaseTitle>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-12">
      <VehicleCard
        v-for="(car, idx) in carList"
        :id="car.id"
        :key="idx"
        variant="left"
        :title="car.title"
        :price="car.price"
        :image-src="car.imageSrc"
        :perks="car.perks"
        :highlight-text="car.highlightText"
        :highlight-variant="car.highlightVariant"
        :eco-label="car.ecoLabel"
        @click="onClickCarCard(car.id as string)"
      />
    </div>
    <div class="flex items-center justify-center my-16">
      <BaseButtonLink
        variant="primary"
        size="xl"
        :to="ctaLink"
        @click="onClickCTA"
      >
        {{ ctaText }}
      </BaseButtonLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CarCard as CarCardType } from '~/types';
import BaseButtonLink from '~/components/BaseButtonLink.vue';
import BaseTitle from '~/components/BaseTitle.vue';
import { useGTM } from '~/composables';
import { TRACKING_EVENTS } from '~/lib/constants';
import VehicleCard from '../cards/VehicleCard.vue';

interface Props {
  title?: string
  ctaText: string
  ctaLink: string
  carList: CarCardType[]
}

defineProps<Props>();

const { triggerEvent } = useGTM();
function onClickCarCard (vehicleId: string) {
  triggerEvent(`${TRACKING_EVENTS.CLICK_VEHICLE_LIST}${vehicleId}`);
}
function onClickCTA () {
  triggerEvent(`${TRACKING_EVENTS.CLICK_VEHICLE_LIST}CTA`);
}
</script>
