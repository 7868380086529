<template>
  <div class="grid-page pt-16">
    <BaseTitle
      v-if="!!title"
      level="h2"
      class="mb-12"
    >
      {{ title }}
    </BaseTitle>

    <div class="grid-container">
      <div
        v-for="(item, idx) in computedItems"
        :key="idx"
        :class="[item.featured ? `featured${idx +1}` : `block${idx +1} min-h-[16rem]`]"
      >
        <CarGridItem
          :description="item.description"
          :image-src="item.imageSrc"
        >
          <template v-if="item?.tag?.title" #header>
            <BaseTag :variant="item.tag?.variant">
              {{ item.tag?.title }}
            </BaseTag>
          </template>

          <template #title>
            <div class="flex items-start">
              <h4 class="inline">
                {{ item.title }}
              </h4>
              <BaseTag
                v-if="item.titleTag"
                size="xs"
                class="ml-1"
                :variant="item.titleTag?.variant"
              >
                {{ item.titleTag?.title }}
              </BaseTag>
            </div>
          </template>

          <template #price>
            <span class="text-brand-secondary">{{ item.price }}</span><span v-if="item.priceBefore" class="ml-2 text-sm line-through">{{ item.priceBefore }}</span>
          </template>
        </CarGridItem>
      </div>
    </div>

    <div v-if="!!ctaLink" class="flex items-center justify-center my-16">
      <BaseButtonLink
        :to="ctaLink"
        variant="primary"
        size="xl"
      >
        {{ ctaText }}
      </BaseButtonLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type ComputedRef } from 'vue';
import BaseTitle from '../BaseTitle.vue';
import BaseButtonLink from '~/components/BaseButtonLink.vue';
import BaseTag from '~/components/BaseTag.vue';
import CarGridItem, { type Props as GridItemProps } from '~/components/grids/CarGridItem.vue';
import type { UiVariant } from '~/types';

interface Item extends Partial<GridItemProps> {
  priceBefore?: string
  featured?: boolean
  tag?: {
    title: string
    variant: UiVariant
  }
  titleTag?: {
    title: string
    variant: UiVariant
  }
}

interface ExtendedItemProps extends Item {}

interface Props {
  maxItems?: 12,
  items: Partial<ExtendedItemProps>[]
  title: string
  ctaLink?: string
  ctaText?: string
}

const props = withDefaults(defineProps<Props>(), {
  maxItems: 12,
  ctaLink: undefined,
  ctaText: undefined,
});

const computedItems = computed(() => props.items.slice(0, props.maxItems)) as ComputedRef<Props['items']>;
</script>

<style scoped>
@media (min-width: 768px) {
  .grid-container {
    @apply grid gap-4;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas:
      "featured1 featured1 block11 block12 block3 block4"
      "featured1 featured1 block11 block12 block3 block4"
      "featured1 featured1 block11 block12 block3 block4"
      "featured1 featured1 block5  block6  featured2 featured2"
      "block7    block8    block9  block10 featured2 featured2"
      "block7    block8    block9  block10 featured2 featured2"
      "block7    block8    block9  block10 featured2 featured2"
  }

  .featured1 {
    grid-area: featured1;
  }

  .featured2 {
    grid-area: featured2;
  }

  .block3 {
    grid-area: block3;
  }

  .block4 {
    grid-area: block4;
  }

  .block5 {
    grid-area: block5;
  }

  .block6 {
    grid-area: block6;
  }

  .block7 {
    grid-area: block7;
  }
  .block8 {
    grid-area: block8;
  }
  .block9 {
    grid-area: block9;
  }
  .block10 {
    grid-area: block10;
  }
  .block11 {
    grid-area: block11;
  }
  .block12 {
    grid-area: block12;
  }
}

@media (max-width: 767px) {
  .grid-container {
    @apply grid gap-4 grid-cols-2;
    grid-area: unset;
    [class^=featured] {
      @apply col-span-2;
    }
  }
}
</style>
